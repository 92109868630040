<template>
    <div>
        <i-field readonly clickable :name="formItem.controlName" :disabled="isDisable" :value="value" :placeholder="formItem.placeholder" :rules="formItem.rules" @click="initShowPicker">
            <template #label>
                <div>
                    <span class="title">{{ formItem.label }}</span>
                    <span v-if="formItem.rules && formItem.rules[0].required" style="color: red">*</span>
                </div>
                <div v-html="formItem.tips"></div>
            </template>
            <template v-if="!formItem.rules" #right-icon>
                <vIcon name="close" @click.stop="clearVal" />
            </template>
        </i-field>
        <i-popup ref="popupInst" :id="currentDomId" v-model="showPicker" @close="searchClear()" position="bottom" get-container="#app">
            <i-search v-model="keywords" v-if="formItem.showSearch" show-action @clear="searchClear()" placeholder="请输入搜索关键词">
                <template #action>
                    <div @click="onSearch">搜索</div>
                </template>
            </i-search>
            <i-picker ref="ipickerinst" swipe-duration="300" :value-key="formItem.txtName" show-toolbar :default-index="defaultIndex" :columns="isSearch ? searchList : columns" @confirm="onConfirm" @cancel="showPicker = false" />
        </i-popup>
        <!--存储数据源-->
        <i-field style="display: none" v-model="sourceData" readonly :name="formItem.controlName + 'SourceData'" />
    </div>
</template>

<script>
import generic from "../../utils/generic";

export default {
    name: "Picker",
    props: {
        formTemplate: null,
        formData: {
            type: Object
        },
        formItem: {
            type: Object,
            default() {
                return {};
            },
        },
        modelVal: [Object, String, Number],
        isDisable: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    data() {
        return {
            value: "",
            columns: [],
            currentDomId: "popup" + Math.floor(Math.random() * 10000),
            showPicker: false,
            sourceData: "",
            defaultIndex: 0,
            keywords: "",
            searchList: [],
            isSearch: false,
            PageIndex: 1,
            sipickerinst: null,

            stashFormItem: {},
        };
    },
    watch: {
        modelVal(newValue) {
            this.value = this.mappingShowName(newValue);
        },
    },
    async mounted() {
        // console.log('mount');
            if(this.formItem.replaceEvalPath) {
                let context = this;
                // console.log('eventCity==',context.formData.eventCity);
                this.formItem = generic.replaceEvalObject(this.jsonCopy(this.formItem),context);
                console.log('picker--formItem',this.formItem.params);
            }
            await this.init();
            this.stashFormItem = this.jsonCopy(this.formItem);

    },
    methods: {
        mappingShowName(source) {
            if (this.formItem.mappingName) {
                // TODO 此处查找时columns是空的，需要处理
                if (this.columns.find((x) => x[this.formItem.valName] == this.modelVal)) {
                    return this.columns.find((x) => x[this.formItem.valName] == this.modelVal)[this.formItem.txtName];
                } else {
                    return "";
                }
            } else {
                return this.$publicFun.getObjType(source) == "Object" ? source[this.formItem.txtName] : source;
            }
        },
        async pickerChange(index) {
            if (this.formItem.postSearch && index === this.columns.length - 1) {
                if (this.pickerChangeLock) {
                    return;
                } else {
                    // 开始搜索重置分页到第一页
                    this.pickerChangeLock = true;
                    this.PageIndex++;
                    let resource;
                    let action = this.formItem.method == "GET" ? "Get" : "common";
                    if (this.formItem.expr) {
                        let context = this;
                        try {
                            eval(this.formItem.expr);
                        } catch (error) {
                            console.log("====执行会议表单项表达式", error, this.formItem.expr);
                        }
                    }
                    let res = await this.$service[action](this.formItem.url, this.formItem.params);
                    setTimeout(() => {
                        this.pickerChangeLock = false;
                    }, 100);
                    if (res && res.success && res.content) {
                        if (this.formItem.dataContentPath) {
                            try {
                                resource = eval("res." + this.formItem.dataContentPath);
                            } catch (error) {
                                console.log(error);
                            }
                        } else {
                            resource = res.content.options || []
                        }
                    }

                    if (this.modelVal != "") {
                        this.value = this.mappingShowName(this.modelVal);
                    }
                    resource && resource.map((item, index) => {
                            if (item[this.formItem.txtName] == this.value) {
                                this.defaultIndex = index;
                                item.defaultIndex = index;
                                this.sourceData = JSON.stringify(item);
                            }
                            if (item.children && item.children.length == 0) {
                                item.children = null;
                            }
                        });
                    if (resource.length == 0) {
                        return;
                    }
                    let oldLength = this.columns.length;
                    this.columns.push(...resource);
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.sipickerinst.setColumnIndex(0, oldLength - 1);
                        }, 50);
                    });
                }
            }
        },
        clearVal() {
            this.value = "";
        },
        initShowPicker() {
            // 初始化分页数据
            this.PageIndex = 1;
            this.pickerChangeLock = false;
            this.showPicker = true;
            this.formItem.params = this.stashFormItem?.params || {};
            console.log(this.formItem);
            this.init();
            if (this.formItem.postSearch) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.sipickerinst = this.$refs.ipickerinst;
                        let domSelector = `#${this.currentDomId} .van-picker-column`;
                        if (document.querySelector(domSelector)) {
                            document.querySelector(domSelector).removeEventListener("touchend", null);
                            document.querySelector(domSelector).addEventListener("touchend", (e) => {
                                e.preventDefault();
                                // 获取当前选中了第几个
                                setTimeout(() => {
                                    this.pickerChange(this.sipickerinst.getColumnIndex(0));
                                }, 600);
                            });
                        }
                    }, 100);
                });
            }
        },
        async init() {
            let resource;
            if (this.formItem.options?.length) {
                resource = this.formItem.options;
            } else {
                let action = this.formItem.method == "GET" ? "Get" : "common";
                if (this.formItem.expr) {
                    let context = this;
                    try {
                        eval(this.formItem.expr);
                    } catch (error) {
                        console.log("====执行会议表单项表达式", error, this.formItem.expr);
                    }
                }
                let res = await this.$service[action](this.formItem.url, this.formItem.params);
                if (res && res.success && res.content) {
                    if (this.formItem.dataContentPath) {
                        try {
                            resource = eval("res." + this.formItem.dataContentPath);
                        } catch (error) {
                            console.log(error);
                        }
                    } else {
                        resource = res.content.options || []
                    }
                }
            }
            if (this.modelVal != "") {
                this.value = this.mappingShowName(this.modelVal);
            }
            resource && resource.map((item, index) => {
                if (item[this.formItem.txtName] == this.value) {
                    this.defaultIndex = index;
                    item.defaultIndex = index;
                    this.sourceData = JSON.stringify(item);
                }
                if (item.children && item.children.length == 0) {
                    item.children = null;
                }
            });
            this.columns = resource || [];
        },
        onConfirm(selectedItem, index) {
            let formTemplateItem = this.formTemplate[0].find((x) => x.controlName == this.formItem.controlName);
            formTemplateItem && (formTemplateItem.selectedValue = selectedItem);
            //把显示到输入框的值显示为label而不是value
            this.value = selectedItem && selectedItem[this.formItem.txtName] || '';
            // console.log('value----',this.value);
            this.showPicker = false;
            this.sourceData = JSON.stringify(selectedItem);
            // console.log(selectedItem);
            this.$emit("callBack", selectedItem, index, this.formItem);
        },
        searchClear() {
            this.keywords = "";
            this.searchList = [];
            this.isSearch = false;
        },
        async onSearch() {
            if (this.pickerChangeLock) {
                return;
            }
            if (this.formItem.postSearch) {
                // 开始搜索重置分页到第一页
                this.PageIndex = 1;
                this.columns = [];
                let resource;
                let action = this.formItem.method == "GET" ? "Get" : "common";
                if (this.formItem.expr) {
                    let context = this;
                    try {
                        eval(this.formItem.expr);
                    } catch (error) {
                        console.log("====执行会议表单项表达式", error, this.formItem.expr);
                    }
                }
                let res = await this.$service[action](this.formItem.url, this.formItem.params);
                if (res && res.success && res.content) {
                    if (this.formItem.dataContentPath) {
                        // console.log('picker--',this.formData);
                        try {
                            resource = eval("res." + this.formItem.dataContentPath);
                        } catch (error) {
                            console.log(error);
                        }
                    } else {
                        resource = res.content.options;
                    }
                }

                if (this.modelVal != "") {
                    this.value = this.mappingShowName(this.modelVal);
                }
                if (resource)
                    resource.map((item, index) => {
                        if (item[this.formItem.txtName] == this.value) {
                            this.defaultIndex = index;
                            item.defaultIndex = index;
                            this.sourceData = JSON.stringify(item);
                        }
                        if (item.children && item.children.length == 0) {
                            item.children = null;
                        }
                    });

                this.columns = resource || [];
            } else {
                let val = this.keywords.trim();
                if (val) {
                    this.isSearch = true;
                    this.searchList = this.columns.filter((item) => {
                        if (item[this.formItem.valName].includes(val) || item[this.formItem.txtName].includes(val)) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                } else {
                    this.searchList = this.columns;
                }
            }
        },
    },
};
</script>

<style scoped></style>
