var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c(
          "div",
          {
            staticClass: "van-cell van-field",
            class: _vm.isDisable ? "van-field--disabled" : "",
          },
          [
            _c("div", { staticClass: "van-cell__title van-field__label" }, [
              _c("div", [
                _c("span", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.formItem.label)),
                ]),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.formItem.rules,
                        expression: "formItem.rules",
                      },
                    ],
                    staticStyle: { color: "red" },
                  },
                  [_vm._v("*")]
                ),
              ]),
              _c("div", { domProps: { innerHTML: _vm._s(_vm.formItem.tips) } }),
            ]),
            _c("div", { staticClass: "van-cell__value van-field__value" }, [
              _c(
                "div",
                {
                  staticClass: "van-field__body",
                  staticStyle: {
                    width: "90%",
                    display: "flex",
                    "flex-wrap": "wrap",
                  },
                  on: { click: _vm.initShowPicker },
                },
                [
                  _vm._l(_vm.selectedItems, function (item, index) {
                    return [
                      _c(
                        "div",
                        { key: index, staticClass: "el-tag el-tag--info" },
                        [
                          _c("div", { staticClass: "el-select__tags-text" }, [
                            _vm._v(_vm._s(item[_vm.formItem.txtName])),
                          ]),
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteSelect(item)
                                },
                              },
                            },
                            [
                              _c("i-icon", {
                                attrs: {
                                  color: "#13161B",
                                  name: "iconguanbi",
                                  size: 11,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  }),
                  _vm.selectedItems.length == 0
                    ? [
                        _c("div", { staticStyle: { color: "#bfbfbf" } }, [
                          _vm._v(_vm._s(_vm.formItem.placeholder)),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _c(
              "span",
              { attrs: { id: "multipleHiddenInput" } },
              [
                _c("i-field", {
                  ref: "inputHidden",
                  attrs: {
                    type: _vm.inputType,
                    rows: "1",
                    autosize: "",
                    name: _vm.formItem.controlName,
                    maxlength: _vm.maxlength,
                    placeholder: _vm.formItem.placeholder,
                    rules: _vm.formItem.rules,
                    "show-word-limit": "",
                  },
                  on: { input: _vm.inputChange },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return undefined
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.modelVal,
                    callback: function ($$v) {
                      _vm.modelVal = $$v
                    },
                    expression: "modelVal",
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm.showPicker
        ? [
            _c(
              "i-popup",
              {
                style: { height: _vm.formItem.postSearch ? "100%" : "50%" },
                attrs: { position: "bottom", "get-container": "#app" },
                model: {
                  value: _vm.showPicker,
                  callback: function ($$v) {
                    _vm.showPicker = $$v
                  },
                  expression: "showPicker",
                },
              },
              [
                _vm.formItem.showSearch
                  ? _c("i-search", {
                      attrs: {
                        "show-action": "",
                        placeholder:
                          _vm.formItem.searchPlaceholder || "请输入搜索关键词",
                      },
                      on: { clear: _vm.onSearch },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "action",
                            fn: function () {
                              return [
                                _c("div", { on: { click: _vm.onSearch } }, [
                                  _vm._v("搜索"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3446203101
                      ),
                      model: {
                        value: _vm.keywords,
                        callback: function ($$v) {
                          _vm.keywords = $$v
                        },
                        expression: "keywords",
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "tb_select_box",
                    style: {
                      height: _vm.formItem.showSearch
                        ? "calc(100% - 54px)"
                        : "100%",
                    },
                  },
                  [
                    _c("div", { staticClass: "select_top" }, [
                      _c(
                        "div",
                        {
                          staticClass: "select_cancel picker_select_btn",
                          attrs: { type: "cancel" },
                          on: {
                            click: function ($event) {
                              return _vm.searchClear()
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _c("div", { staticClass: "select_title" }, [
                        _vm._v("请选择"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "select_confirm picker_select_btn",
                          attrs: { type: "confirm" },
                          on: {
                            click: function ($event) {
                              _vm.showPicker = false
                            },
                          },
                        },
                        [_vm._v("确定")]
                      ),
                    ]),
                    _vm.currentColumns && _vm.currentColumns.length
                      ? _c(
                          "div",
                          {
                            staticClass: "select_end",
                            style: {
                              maxHeight: _vm.formItem.showSearch
                                ? "100%"
                                : "60vh",
                            },
                            on: { scroll: _vm.scrollColumns },
                          },
                          [
                            _vm._l(_vm.currentColumns, function (item, index) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "select_item picker_select_btn",
                                    style: _vm.formItem.postSearch
                                      ? {
                                          padding: "10px",
                                          boxSizing: "border-box",
                                          height: "auto",
                                        }
                                      : {},
                                    on: {
                                      click: function ($event) {
                                        return _vm.addSelectItem(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm.formItem.itemTemplate
                                      ? _c(
                                          _vm.$evalTemplate(
                                            "Vnode",
                                            _vm.formItem.itemTemplate,
                                            item
                                          ),
                                          { tag: "component" }
                                        )
                                      : [
                                          _vm._v(
                                            _vm._s(item[_vm.formItem.txtName])
                                          ),
                                        ],
                                    _vm.checkIsIncluding(item)
                                      ? [
                                          _c("i-icon", {
                                            attrs: {
                                              color: "#eb5353",
                                              name: "iconcheckboxok",
                                              size: 15,
                                            },
                                          }),
                                        ]
                                      : [
                                          _c("i-icon", {
                                            attrs: {
                                              color: "#13161B",
                                              name: "iconcheckboxno",
                                              size: 15,
                                            },
                                          }),
                                        ],
                                  ],
                                  2
                                ),
                              ]
                            }),
                            _vm.onPaginLock
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      "justify-content": "center",
                                      height: "40px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "circle-loader",
                                        staticStyle: {
                                          transform: "scale(0.3)",
                                        },
                                      },
                                      [
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }