
const validatorMap = {
    mobile: {func: toCheckMobile, msg: '请输入有效手机号'},
    email: {func: toCheckEmail, msg: '请输入有效邮箱'},
    idNum: {func: toCheckIdent, msg: '请输入有效身份证件'},
    bankAccountNumber: {func: toCheckBankId, msg: '请输入有效的数字银行账号'},
    positiveInteger: {func: toCheckInteger, msg: '请填写大于0的整数'},
    nonNegativeInteger: {func: nonNegativeInteger, msg: '请填写非负整数'},
    speakersNum: {func: handleSpeakersNum, msg: '讲者数量不超过三个'}
}

const handleSpeakersNum = (val) => {
    return new Promise((resolve, reject) => {
        resolve()
    })
}
// 手机数字验证
function toCheckMobile(val) {
    return new Promise((resolve) => {
        let _Number = /^[1][3,4,5,6,7,8,9][0-9]{9}$/g;
        let _Desensitization = /[*]+/g;
        let _mix = /[^\d|^*]/g
        if (!val) {
            resolve()
        } else if (_mix.test(val)) {
            resolve(false)
        } else if (!_Desensitization.test(val) && !_Number.test(val)) {
            resolve(false)
        } else {
            resolve()
        }
    });
}

// 邮箱验证
function toCheckEmail(val) {
    return new Promise((resolve) => {
        let _email =  /^.+@.+\..{2,4}$/
        let _Desensitization = /^[*]+/;
        if (!val) {
            resolve()
        } else if (!_email.test(val) && !_Desensitization.test(val)) {
            resolve(false)
        } else {
            resolve()
        }
    });
}

// 身份证验证
function toCheckIdent(val) {
    return new Promise((resolve) => {
        let _IDRe18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        let _IDre15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;
        let _Desensitization = /[*]+/g;
        let _mix = /[^\d|^*|^Xx]/g
        if (!val) {
            resolve()
        } else if (_mix.test(val)) {
            resolve(false)
        } else if (!_Desensitization.test(val) && !_IDRe18.test(val) && !_IDre15.test(val)) {
            resolve(false)
        } else {
            resolve()
        }
    });
}

// 银行卡号数字验证
function toCheckBankId(val) {
    return new Promise((resolve) => {
        // let _Number = /^\d+$/g;
        // let _Desensitization = /^[*]+/;
        let _mix = /[^\d|^*]/g
        if (!val) {
            resolve()
        // } else if (!_Number.test(val) && !_Desensitization.test(val)) {
        } else if (_mix.test(val)) {
            resolve(false)
        } else {
            resolve()
        }
    });
}

// 正整数校验
function toCheckInteger(val) {
    return new Promise((resolve) => {
        let reg = /^[1-9]\d*$/
        if (reg.test(val)) {
            resolve()
        } else {
            resolve(false)
        }
    });
}

// 非负整数校验
function nonNegativeInteger(val) {
    return new Promise((resolve) => {
        let reg = /^([1-9]\d*|[0]{1,1})$/
        if (reg.test(val)) {
            resolve()
        } else {
            resolve(false)
        }
    });
}

export default validatorMap
