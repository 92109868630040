<template>
    <div>
        <i-field readonly clickable validate-trigger="onChange" :name="formItem.controlName" :disabled="isDisable" :value="value" :placeholder="formItem.placeholder" :rules="rules" @click="initPicker">
            <template #label>
                <div>
                    <span class="title">{{ formItem.label }}</span>
                    <span v-if="formItem.rules && formItem.rules[0].required" style="color: red">*</span>
                </div>
                <div v-html="formItem.tips"></div>
            </template>
        </i-field>
        <i-popup v-model="showPicker" position="bottom" get-container="#app">
            <DatetimePicker v-if="formItem.componentType === 'time'" v-model="currentDate"
                                type="time"
                                title="选择时间"
                                :min-hour="0"
                                @confirm="onConfirm" @cancel="showPicker = false"
                                :max-hour="23"/>
            <i-datetime-picker v-else v-model="currentDate" :type="formItem.componentType ? formItem.componentType : 'datetime'" :title="formItem.label" :min-date="minDate" :max-date="maxDate" :filter="filter" @confirm="onConfirm" @cancel="showPicker = false" />
        </i-popup>
    </div>
</template>

<script>
import { DatetimePicker } from 'vant';
export default {
    name: "iDateTimePicker",
    components:{
        DatetimePicker
    },
    props: {
        formData: null,
        formItem: {
            type: Object,
            default() {
                return {};
            },
        },
        modelVal: [String, Number],
        isDisable: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    data() {
        return {
            value: "",
            rules: [],
            showPicker: false,
            minDate: new Date(),
            maxDate: new Date(new Date().getFullYear() + 1, new Date().getMonth(), 1),
            currentDate: '',
        };
    },
    watch: {
        showPicker(val){
            if(val){
                this.minDateRules(this.formItem.minDateType);
            }
        }
    },
    async created() {
        this.rules = this.formItem.rules.map(i => {
            return {...i, validator: eval(i.validator)}
        })
        this.value = this.modelVal;
        if (this.modelVal != "") {
            switch (this.formItem.componentType){
                case 'time':
                    this.currentDate = this.modelVal;
                    break;
                case 'datetime':
                    this.currentDate = new Date(this.modelVal);
                    break;
                case 'date':
                    this.currentDate = new Date(this.modelVal.split('T')[0])
                    break;
            }
        }else {
            if(this.formItem.componentType === 'date'){
                this.currentDate = new Date();
            }else if(this.formItem.componentType === 'time'){
                let current = new Date();
                let h = current.getHours();
                let m = current.getMinutes()
                this.currentDate = (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m);
            }
        }
        // 默认的限制规则
        this.minDateRules(this.formItem.minDateType);
    },

    methods: {
        async initPicker() {
            this.showPicker = true;
        },
        reassign(currentDate) { // 回显的函数
            console.log('会先日期--------',currentDate);
            if (!currentDate) {
                this.value = ''
                return false
            }
            let dateTime = this.handleDateType(this.formItem.componentType, currentDate);
                // this.formItem.componentType === 'time' ? currentDate : this.moment(currentDate).format("YYYY/MM/DD HH:mm");
            this.value = dateTime;
            this.currentDate = currentDate;
        },
        // 处理时间类型对应不同的时间格式
        handleDateType(type, date){
            let result = date;
            switch (type){
                case 'time':
                    return date;
                case 'datetime':
                    return this.moment(result).format("YYYY/MM/DD HH:mm:ss")
                case 'date':
                    return this.moment(result).format("YYYY/MM/DD")
                default:
                    return this.moment(result).format("YYYY/MM/DD HH:mm:ss")
            }
        },
        minDateRules(type) { // 设置最小时间
            switch (type) {
                case "aWeekLater": {
                    // 下周一开始
                    const dateInstance = this.moment(this.$store.state.miceInfo.CreatedOn || new Date());
                    const nextMonday = dateInstance.subtract(dateInstance.day() - 8, "days").format("YYYY/MM/DD");
                    this.minDate = new Date(nextMonday + " 00:00:00");
                    break;
                }
                case "oneDayLater": {
                    // 明天开始
                    const dateInstance = this.moment(this.$store.state.miceInfo.CreatedOn || new Date());
                    const nextDay = dateInstance.subtract(-1, "days").format("YYYY/MM/DD");
                    this.minDate = new Date(nextDay + " 00:00:00");
                    break;
                }
              case "today": {
                    // 当前时间开始
                    let now = new Date();
                    this.minDate = new Date(now.getTime());
                    break;
                }
              case "fiveYearsBefore": {
                let now = new Date();
                this.minDate = new Date(now.getTime() - 157680000000);
                break;
              }
                default: // 如果传入(配置)的minDateType为日期，eg.2022/12/03，可以直接设置为最小日期
                    this.minDate = type ? new Date(type) : new Date();
                    break;
            }
            this.maxDateRules(this.formItem.maxDateType);
        },
        maxDateRules(type) {
            switch (type) {
                case "twoWeekLater": {
                    // 2周后
                    const dateInstance = this.moment(this.minDate);
                    const twoWeek = dateInstance.subtract(-14, "days").format("YYYY/MM/DD");
                    this.maxDate = new Date(twoWeek + " 00:00:00");
                    break;
                }
                case "oneDayLater": {
                    // 明天开始
                    const dateInstance = this.moment(this.$store.state.miceInfo.CreatedOn || new Date());
                    const nextDay = dateInstance.subtract(-1, "days").format("YYYY/MM/DD");
                    this.maxDate = new Date(nextDay + " 00:00:00");
                    break;
                }
            }
        },
        onConfirm(time) {
            let dateTime;
            switch (this.formItem.componentType) {
                case 'time':
                    dateTime = time;
                    break;
                case 'date':
                    dateTime = this.moment(time).format("YYYY/MM/DD");
                    break;
                default:
                    dateTime = this.moment(time).format("YYYY/MM/DD HH:mm");
            }
            this.value = dateTime;
            this.showPicker = false;
            this.$emit("callBack", time, null, this.formItem);
        },
        filter(type, options) {
            if (type === "minute") {
                return options.filter((option) => option % 10 === 0);
            }
            return options;
        },
    },
};
</script>

<style scoped></style>
